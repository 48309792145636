.reviewerbyadmin-page {
    padding: 80px 10px;
    min-height: 100vh;
    margin-bottom: 100px;
}

.reviewerbyadmin-heading {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}