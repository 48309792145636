.allblogs-page{
    min-height: 100vh;
}

.blogcard{
    min-height: 200px;
}

.blogcard-container {
    position: relative;
    text-align: center;
    color: white;
  }

  .blogcard-container-img{
    width: 100%;
    height: 120px;
  }

  .blogcard-container-text {
    font-size: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .react-select-dropdown{
    max-width: 300px;
  }