.quill-editor-container {
    height: 20em;
    /* Adjust the height as needed */
    overflow-y: auto;
}

.quill-editor-container .ql-toolbar {
    position: sticky;
    top: 0;
    background-color: #fff;
    /* Adjust the background color as needed */
    z-index: 1;
}



