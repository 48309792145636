.password-change-page {
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.password-change-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
}

.password-change-form .password-change-button {
    margin-top: 20px;
}
