.settingspage{
    padding: 80px 10px;
    min-height: 100vh;
}

.settingspage-heading {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}