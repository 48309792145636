.forgot-password-page{
    background-color: #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
}

.forgot-password-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
}

.forgot-password-form .forgot-password-button {
    margin-top: 20px;
}