.editblogpage {
    margin-top: 70px;
    /* margin-bottom: 100px; */
}

.edit-blog-heading {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.submit-editedblog {
    margin-top: 10px;
    margin-left: 10px;
}

.goback-editedblog {
    margin-left: 10px;
    margin-top: 10px;
}

.editblogfields {
    margin-top: 10px;
}

.star {
    cursor: pointer;
}

.filled {
    color: #ffbf00;
    /* Change this to the color of your filled stars */
}