.my-profile-page{
    padding: 80px 10px;
    min-height: 100vh;
}

.my-profile-heading {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.profile-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-picture img {
    width: 150px;
    /* Adjust the width as needed */
    height: 150px;
    /* Adjust the height as needed */
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
}


.custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border-radius: 4px;
    border: none;
    transition: background-color 0.3s ease;
}

.custom-file-upload:hover {
    background-color: #45a049;
}

.custom-file-upload input[type="file"] {
    display: none;
}