.login-page {
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.login-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
}

.password-input {
    position: relative;
}

.toggle-password {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #999;
    cursor: pointer;
}

.toggle-password:hover {
    color: #333;
}


.login-form .loginbutton {
    margin-top: 20px;
}

.login-form .forgotpassbutton {
    margin-top: 20px;
    margin-left: 5px;
}