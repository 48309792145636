.signup-page {
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.signup-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.signup-form .form-group {
    margin-bottom: 20px;
}

.signup-form .password-input {
    position: relative;
}

.signup-form .password-input i {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #999;
    cursor: pointer;
}

.signup-form .password-input i:hover {
    color: #333;
}


.signup-form .confirmpassword-input {
    position: relative;
}

.signup-form .confirmpassword-input i {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #999;
    cursor: pointer;
}

.signup-form .confirmpassword-input i:hover {
    color: #333;
}

.signup-form .signbutton{
    margin-top: 20px;
}