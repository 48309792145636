.developer-page {
    padding: 80px 10px;
    min-height: 100vh;
}

.developer-heading {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.profile-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skills{
    display: flex;
    justify-content:start;
    flex-wrap: wrap;

}
.skill-item{
    margin: 5px;
}

.other-projects{
    display: flex;
    justify-content:space-evenly;
    flex-wrap: wrap;
}

.other-project-item{
    width: 350px;
    margin: 5px;
}

.other-project-item-body{
    height: 100px;
}